.grid-view {
  .grid-view-toolbar {
    select.grid-view-size {
      width: auto;
      margin: 0 0.5em;
    }

    .grid-view-toolbar-buttons button:nth-child(n + 2) {
      margin-left: 0.5rem;
    }
  }

  thead {
    font-size: 0.8rem;
    th {
      padding: 0.3rem 0.2rem;

      .gridview-btn-visibility {
        color: $primary;
        float: left;
        margin: 0.25rem 0.5rem;
        cursor: pointer;
        transition: all 0.3s;
        > svg {
          margin-right: 0.2rem;
        }

        &:hover {
          transition: none;
          color: lighten($primary, 15%);
        }

        &.hidded {
          transition: all 0.3s;
          color: darken($secondary, 15%);
          position: relative;
          &::after {
            content: "";
            border-bottom: 0.13rem solid $secondary;
            position: absolute;
            left: -5%;
            top: 42.5%;
            width: 110%;
          }
          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
  tbody {
    font-size: 0.8rem;
  }

  ul.pagination {
    margin-bottom: 0;
  }

  .page-link {
    color: $primary;
  }
  .page-item.active .page-link {
    background-color: $primary;
    border-color: $primary;

    &:focus {
      @extend .focus-border;
    }
  }
}
