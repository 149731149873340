.capture > * {
  width: auto;
  min-height: 15rem;
  position: relative;

  .file-upload-image > svg {
    color: $CSDPurple;
    opacity: 0.5;
    margin: 0.75rem auto;
    font-size: 12rem;
  }

  .toolbar-buttons {
    position: absolute;
    bottom: 0.75rem;
    left: 0.5rem;
    right: 0.5rem;

    button {
      padding: 0.375rem 0;
      opacity: 0.75;
    }
  }

  video {
    width: 100%;
  }

  img {
    width: 100%;
  }
}
