.quick-button {
  padding: 0.8rem;
  text-align: center;
  * {
    margin: 0.2rem auto;
    display: block;
  }
}

.spinner-wrapper {
  display: inline-block;
  margin-right: 8px;
}

.width80 {
  width: 80%;
}

.width100 {
  width: 100%;
}

.toogle-button {
  white-space: nowrap;

  svg {
    margin-right: 0 !important;
  }
  .btn-text-wrapper {
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;
    max-width: 0;
    transition: max-width 0.3s ease-in-out;
  }

  &:hover {
    .btn-text-wrapper {
      margin-left: 5px;
      max-width: 300px;
    }
  }
}

.btn-link.link-primary,
.btn-link.link-info,
.btn-link.link-secondary,
.btn-link.link-success,
.btn-link.link-danger,
.btn-link.link-inactive,
.btn-link.link-warning {
  &:focus,
  &:active {
    outline: none;
  }
  svg {
    transition: transform 0.3s ease-in-out;
  }
  &.active svg {
    transform: rotate(-180deg);
  }
}

.btn-xs {
  font-size: x-small;
  svg {
    font-size: larger;
  }
}
.btn-sm {
  font-size: small;
  svg {
    font-size: larger;
  }
}
.btn-md {
  font-size: 1rem;
  &.quick-button {
    border-radius: 0.3rem;
    svg {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
  }
}
.btn-lg {
  font-size: large;
  svg {
    font-size: larger;
  }
}
.btn-xl {
  font-size: x-large;
  svg {
    font-size: larger;
  }
}
.btn-height{
  height: 100px;
}
