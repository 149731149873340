$background-color: lighten($primary, 60%);
$dropzone-text-title: #525252;
$dropzone-text-subtitle: #878787;
$dropzone-file-border: #525252;
$single-border: #878787;

.file-upload {
  &.file-upload-single {
    border: 1px solid $single-border;
    border-radius: 5px;
    padding: 10px;

    .form-control {
      background-color: $inactive;
      cursor: pointer;
      display: flex;
      align-items: center;

      .file-size {
        font-size: 0.8em;
        margin-left: 5px;
      }
      svg {
        margin-right: 10px;
        font-size: 1.2em;
      }
    }
    .single-remove {
      &.inactive {
        @extend .btn-inactive;
      }

      &.active {
        @extend .btn-danger;
      }
    }
  }

  &.file-upload-dropzone {
    background-color: #fff;
    border: dashed 2px $primary;
    width: 90%;
    margin: 0 5%;
    min-height: 160px;
    padding: 20px;
    padding-bottom: 5px;
    border-radius: 2.5%;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;

    &:hover,
    &.hover {
      background-color: $background-color;
    }

    .dropzone-text {
      p.title {
        color: $dropzone-text-title;
        font-size: 1.2em;
        font-weight: 600;
      }
      p.subtitle {
        color: $dropzone-text-subtitle;
      }
      .highlight {
        color: $primary;
      }
    }

    .dropzone-file {
      width: 124px;
      height: 118px;
      background-color: #fff;
      border: solid 1px $dropzone-file-border;
      border-radius: 5px;
      margin: 5px;
      text-align: center;
      padding: 10px;
      cursor: default;
      text-align: center;
      position: relative;
      box-shadow: 1px 1px 6px #878787;
      svg {
        font-size: 50px;
        opacity: 0.9;
        margin-bottom: 10px;
      }
      span {
        max-width: 90%;
        font-size: 0.8em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;

        &.dropzone-file-size {
          font-size: 0.7em;
        }
      }

      &:hover {
        border-color: $primary;
        color: $primary;
        box-shadow: 1px 1px 8px $primary;
      }
      .dropzone-file-close {
        position: absolute;
        right: -10px;
        top: -10px;
        color: $danger;
        width: 26px;
        height: 26px;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
        padding: 2px;
        cursor: pointer;
        &:hover {
          right: -12px;
          top: -12px;
          width: 30px;
          height: 30px;
        }
      }
    }

    .dropzone-maxFiles {
      @extend .text-danger;
      width: 100%;
      text-align: right;
      text-shadow: 0px 0px 10px $danger;
      transition: 0.3s ease-out;
      &.effect {
        text-shadow: 0px 0px 10px lighten($danger, 30%);
        color: lighten($danger, 30%) !important;
      }
    }
  }
}
