.botaoImprimir {
  float: right;
  background-color: white;
}

.styleBordaExternaBancoDoBrasil {
  border: 1px solid black;
  padding: 12px;
  background-color: #e0e00421;
}

.styleBordaExternaBanrisul {
  border: 1px solid black;
  padding: 12px;
  background-color: #d6f1ff21;
}

.stylePadrao {
  font-size: 14px;
  font-weight: bold;
}

.styleDeEmissao {
  font-size: 14px;
  font-weight: bold;
  position: relative;
  left: 70px;
}

.styleIdentifacaoEmpresa {
  font-size: 12px;
  font-weight: bold;
}

.styleDescricoesAdicionais {
  font-size: 12px;
  font-weight: bold;
}

.styleValorPorExtenso {
  font-size: 12px;
  font-weight: bold;
  color: blue;
  border-bottom: 1px solid black;
  padding-left: 40px !important;
}

.styleDataEmissaoDia {
  padding-left: 20%;
  font-size: 12px;
  font-weight: bold;
  color: blue;
  position: relative;
  left: 80px;
}

.styleDataEmissaoMes {
  padding-left: 0;
  font-size: 12px;
  font-weight: bold;
  color: blue;
  position: relative;
  left: 68px;
}

.styleDataEmissaoAno {
  font-size: 12px;
  font-weight: bold;
  color: blue;
  padding-left: 15px;
  position: relative;
  left: 40px;
}

.styleValorCheque {
  font-size: 12px;
  font-weight: bold;
  color: blue;
  padding-left: 20px;
  position: relative;
  top: -7px;
}

.styleCliente {
  font-size: 12px;
  font-weight: bold;
  color: blue;
  padding-left: 40px;
  position: relative;
  top: -7px;
}

.styleLinhaAssinatura {
  border-bottom: 3px solid black;
}

@media print {
  .styleBordaExternaBancoDoBrasil {
    border: 0;
    background-color: white;
  }

  .styleBordaExternaBanrisul {
    border: 0;
    background-color: white;
  }

  .stylePadrao {
    visibility: hidden;
  }

  .styleDeEmissao {
    visibility: hidden;
  }
  .styleIdentifacaoEmpresa {
    display: none;
  }
  .styleDescricoesAdicionais {
    visibility: hidden;
  }

  .styleValorPorExtenso {
    color: black;
    border: 0;
    padding-left: 40px !important;
    position: relative;
    top: -20px;
    left: 100px;
    font-size: 16px;
    font-weight: bold;
  }

  .styleDataEmissaoDia {
    color: black;
    position: relative;
    left: 85px;
    top: 35px;
    font-size: 16px;
    font-weight: bold;
  }

  .styleDataEmissaoMes {
    color: black;
    position: relative;
    left: 80px;
    top: 35px;
    font-size: 16px;
    font-weight: bold;
  }

  .styleDataEmissaoAno {
    color: black;
    position: relative;
    left: 50px;
    top: 35px;
    font-size: 16px;
    font-weight: bold;
  }
  .styleValorCheque {
    color: black;
    padding-left: 20px;
    position: relative;
    top: -5px;
    font-size: 16px;
    font-weight: bold;
  }

  .styleCliente {
    color: black;
    padding-left: 40px;
    left: 100px;
    position: relative;
    top: -5px;
    font-size: 16px;
    font-weight: bold;
  }

  .styleLinhaAssinatura {
    visibility: hidden;
  }

  .logo {
    visibility: hidden;
  }
}
