.steps {
  .step-header {
    display: flex;
    .step-fill {
      display: flex;
      min-width: 100%;
      flex: 1 0 auto;
      height: 0.5rem;
      margin-top: 12px;
      border-radius: 0.25rem;
      background-color: $inactive;
    }
    .step-index {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: inline-block;
      justify-content: center;
      margin-right: 0.3rem;
      background-color: $inactive;
      color: contrast($inactive);
    }
  }

  .step-header-vertical {
    float: left;
    .step-fill {
      display: flex;
      flex: 1;
      height: 0.5rem;
      margin-top: 12px;
      border-radius: 0.25rem;
      margin-right: 5px;
      background-color: $inactive;
    }
    .step-index {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: inline-block;
      justify-content: center;
      margin-right: 0.3rem;
      background-color: $inactive;
      color: contrast($inactive);
    }
  }

  .step {
    flex: 1;
    margin-right: 1rem;
    text-align: center;
    cursor: pointer;
    &:last-of-type {
      margin-right: 0;
    }

    .step-index {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: inline-block;
      justify-content: center;
      margin-right: 0.3rem;
      background-color: $inactive;
      color: contrast($inactive);
    }
    .step-title {
      font-weight: bold;
      color: #525252;
    }
    .step-description {
      font-size: 0.8rem;
    }
  }

  .stepvertical {
    padding-top: 10px;
    flex: 1;
    margin-right: 1rem;
    cursor: pointer;
    &:last-of-type {
      margin-right: 0;
    }

    .step-index {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: inline-block;
      justify-content: center;
      margin-right: 0.3rem;
      background-color: $inactive;
      color: contrast($inactive);
      text-align: center;
    }
    .step-title {
      font-weight: bold;
      color: #525252;
    }
    .step-description {
      font-size: 0.8rem;
    }
  }

  .stephorizontal {
    padding-top: 10px;
    flex: 1;
    margin-right: 0.1rem;
    min-width: max-content;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:last-of-type {
      margin-right: 0;
    }

    .step-index {
      display: none;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      // display: inline-block;
      justify-content: center;
      margin-right: 0.3rem;
      background-color: $inactive;
      color: contrast($inactive);
      text-align: center;
    }
    .step-title {
      padding-right: 20px;
      font-weight: bold;
      color: #525252;
    }
    .step-description {
      font-size: 0.8rem;
    }
  }

  .step-content {
    padding: 1rem 0.5rem;
  }
  .step-controls {
    @extend .d-flex;
    @extend .justify-content-between;
    .btn:first-of-type svg {
      margin-right: 0.5rem;
    }
    .btn:last-of-type svg {
      margin-left: 0.5rem;
    }
  }
}

@each $theme, $color in $theme-colors {
  .steps.steps-#{$theme} {
    .step-header {
      .step-fill.visited {
        background-color: $color;
      }
      .step {
        &:hover {
          .step-index {
            box-shadow: 0 0 15px $color;
          }
          .step-index,
          .step-title,
          .step-description {
            color: $color;
          }
        }
        &.visited {
          .step-index {
            background-color: $color;
            color: color-contrast($color);
          }
          .step-title,
          .step-description {
            color: $color;
          }
        }
      }
    }

    .step-header-vertical {
      .step-fill.visited {
        background-color: $color;
      }
      .stepvertical {
        &:hover {
          .step-index {
            box-shadow: 0 0 15px $color;
          }
          .step-index,
          .step-title,
          .step-description {
            color: $color;
          }
        }
        &.visited {
          .step-index {
            background-color: $color;
            color: color-contrast($color);
          }
          .step-title,
          .step-description {
            color: $color;
          }
        }
      }
    }
  }
}
