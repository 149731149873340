.dropdown {
  cursor: pointer;
  .dropdown-display {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .dropdown-list {
    @extend .dropDownCommonStyle;
    width: 100%;
    position: absolute;
    z-index: 9991;
    background-color: #fff;
    max-height: 10rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background: lighten($primary, 35%);
    }

    .dropdown-list-item {
      padding: 0.3rem 1rem;
      border-bottom: 0.01em solid #ebebeb;
      &[data-multiselect] {
        padding: 0.25rem 0.5rem;
      }
      &:hover {
        @extend .bg-gradient;
        background-color: $primary;
        color: color-contrast($primary);
      }

      .form-check-label {
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
