.properties {
  padding-left: 0.625rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
}

.actionButton {
  width: 100%;
}

.tire_properties {
  border: 1px solid gray;
  padding: 0.625rem;
  border-radius: 3px;
}

.rotation_properties {
  border: 1px solid gray;
  padding: 0.625rem;
  border-radius: 3px;
}
