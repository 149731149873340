@mixin switch($res: 'sm') {
  $index: 1rem;
  $mainVal: 1rem;

  @if $res == 'md' {
    $index: 2rem;
    $mainVal: 1.5rem;
  } @else if $res == 'lg' {
    $index: 3rem;
    $mainVal: 2rem;
  } @else if $res == 'xl' {
    $index: 4rem;
    $mainVal: 2.5rem;
  }

  margin-bottom: calc($index / 2); /* JUST FOR STYLING PURPOSE */

  .form-check-input {
    height: $mainVal;
    width: calc(#{$index} + 0.75rem);
    border-radius: $mainVal * 2;
  }
}

.form-switch-right {
  @extend .form-switch;
  padding-left: 0;
  display: flex;
  align-items: center;

  .form-check-input {
    float: none;
    margin-left: 0.5em;
  }
}
.form-check-input {
  clear: left;
}
.form-switch.form-switch-sm,
.form-switch-right.form-switch-sm {
  @include switch();
}

.form-switch.form-switch-md,
.form-switch-right.form-switch-md {
  @include switch('md');
}

.form-switch.form-switch-lg,
.form-switch-right.form-switch-lg {
  @include switch('lg');
}

.form-switch.form-switch-xl,
.form-switch-right.form-switch-xl {
  @include switch('xl');
}

@mixin switch-square($res: 'sm') {
  $index: 0.9rem;

  @if $res == 'md' {
    $index: 1.1;
  } @else if $res == 'lg' {
    $index: 1.35;
  } @else if $res == 'xl' {
    $index: 1.7;
  }

  .form-switch-square-label {
    width: calc(48px * #{$index});
    height: calc(26px * #{$index});
    .form-switch-square-span {
      &::before {
        top: calc(2px * #{$index});
        left: calc(2px * #{$index});
        width: calc(20px * #{$index});
        height: calc(20px * #{$index});
      }
      &::after {
        right: calc(1px * #{$index});
        font-size: calc(10px * #{$index});
      }

      &[data-checked='true']::before {
        transform: translateX(calc(22px * #{$index}));
      }
    }
  }
}
.form-switch-square.form-switch-sm {
  @include switch-square();
}

.form-switch-square.form-switch-md {
  @include switch-square('md');
}

.form-switch-square.form-switch-lg {
  @include switch-square('lg');
}

.form-switch-square.form-switch-xl {
  @include switch-square('xl');
}

.form-switch-square {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  .form-switch-square-label {
    width: 48px;
    height: 26px;

    .form-switch-square-span {
      position: relative;
      display: block;
      height: inherit;
      cursor: pointer;
      border: 1px solid #d8dbe0;
      border-radius: 0.25rem;
      background-color: #fff;
      transition: 0.3s ease-out;

      &::before {
        background-color: #fff;
        transition: 0.3s ease-out;

        position: absolute;
        top: 2px;
        left: 2px;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        content: '';
        border: 1px solid #d8dbe0;
        border-radius: 0.125rem;
        z-index: 2;
      }

      &::after {
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 50%;
        margin-top: -0.5em;
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
        color: #c4c9d0;
        text-align: center;
        content: attr(data-unchecked-label);
        transition: inherit;

        right: 1px;
      }

      &[data-checked='true'] {
        &::before {
          transform: translateX(22px);
        }
        &::after {
          left: 1px;
          content: attr(data-checked-label);
        }
      }
    }
  }
  &.form-switch-primary
    > .form-switch-square-label
    > .form-switch-square-span[data-checked='true'] {
    border-color: $primary;
    &::before {
      background-color: $primary;
      border-color: $primary;
    }
    &::after {
      color: $primary;
    }
  }
}
