.loader-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  filter: alpha(opacity=80);
  opacity: 0.8;
  z-index: 500;
}
.loader-spinner {
  display: inline-block;
  position: absolute;
  width: 96px;
  height: 96px;
  z-index: 501;
  left: calc(50% - 48px);
  top: calc(50% - 48px);

  @keyframes lds-ripple {
    0% {
      top: 42px;
      left: 42px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 87px;
      height: 87px;
      opacity: 0;
    }
  }
}
