.cursor-pointer {
  cursor: pointer;
}

.bordered {
  border: 1px solid #ced4da;
}
.border-top {
  border-top: 1px solid #ced4da;
}

.focus-border {
  border-color: lighten($primary, 5%);
  box-shadow: 0 0 0 0.2rem rgba($primary, 0.2);
}
.bg-inactive {
  background-color: $inactive !important;
}
.bg-gradient-info {
  background: $info;
  background: linear-gradient(45deg, lighten($info, 5%) 0%, $info 100%);
  border-color: $info;
}
.bg-gradient-primary {
  background: $primary;
  background: linear-gradient(45deg, lighten($primary, 10%) 0%, $primary 100%);
  border-color: $primary;
}
.bg-gradient-secondary {
  background: $secondary;
  background: linear-gradient(
    45deg,
    lighten($secondary, 10%) 0%,
    $secondary 100%
  );
  border-color: $secondary;
}

.btn:not(.quick-button) > svg:not(:only-child) {
  margin-right: 5px;
}

/* - - top left - - */
.br-tl-1 {
  border-top-left-radius: ($spacer * 0.25) !important;
}

.br-tl-2 {
  border-top-left-radius: ($spacer * 0.5) !important;
}

.br-tl-3 {
  border-top-left-radius: $spacer !important;
}

.br-tl-4 {
  border-top-left-radius: $spacer + ($spacer * 0.25) !important;
}

.br-bl-1 {
  border-bottom-left-radius: ($spacer * 0.25) !important;
}

.br-bl-2 {
  border-bottom-left-radius: ($spacer * 0.5) !important;
}

.br-bl-3 {
  border-bottom-left-radius: $spacer !important;
}

.br-bl-4 {
  border-bottom-left-radius: $spacer + ($spacer * 0.25) !important;
}

/*OVERRIDE BOOTSTRAP*/
.form-control,
.form-select {
  font-size: 0.8rem;
  &:focus {
    @extend .focus-border;
  }
}
.form-label {
  margin-bottom: 0.2rem;
}
.input-group > :not(:first-child):not(.dropdown-menu) input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group > .dropdown-toggle:nth-last-child(n + 3) {
  button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.widget-square {
  height: 100%;
  display: flex;
  align-items: center;
}
.spinner {
  div {
    position: absolute;
    border: 4px solid $primary;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

.dropdown-item > svg:not(:only-child) {
  margin-right: 5px;
}

.dropDownCommonStyle {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: $box-shadow;
}

@import './components/autocomplete.scss';
@import './components/button.scss';
@import './components/capture.scss';
@import './components/checkbox.scss';
@import './components/datepicker.scss';
@import './components/dropdownlist.scss';
@import './components/dropdownmulti.scss';
@import './components/fileupload.scss';
@import './components/gridview.scss';
@import './components/loader.scss';
@import './components/modal.scss';
@import './components/notification.scss';
@import './components/panel.scss';
@import './components/popover';
@import './components/steps.scss';
@import './components/switch.scss';
@import './components/textbox.scss';
@import './components/toolbar.scss';

.panel.CSDPage {
  border-top-left-radius: 0;
}

.example-view {
  padding: 0.25rem;
  button {
    background: none;
    border: none;
    padding: 0;
    margin-right: 1rem;
    svg {
      color: lighten($CSDPurple, 40%);
    }
    &:last-of-type {
      margin-right: 0;
    }
    &.active {
      svg {
        color: $CSDPurple;
      }
    }
    &:hover {
      svg {
        color: lighten($CSDPurple, 20%);
      }
    }
  }
}
