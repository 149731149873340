.CSDTextbox {
  input.clear {
    padding-right: 1.8rem;
  }
  .texbox-inner-content {
    position: absolute;
    right: 0.4rem;
    top: 0.5rem;
    padding-right: 0.25rem;
    background-color: #fff;
    .textbox-clear {
      cursor: pointer;
      color: $primary;
      &:hover {
        color: darken($danger, 15%);
      }
    }
  }
}
