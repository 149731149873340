.wrapper {
  font-weight: bold;
}
.label {
  line-height: 2.5;
}

.value {
  font-size: 1.5rem;
}

.information {
  margin-top: 1rem;
  border-bottom: 1px solid gray;
}
