.tireContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected {
  outline: 3px solid blue;
}

.tire {
  width: 2rem;
  height: 7.375rem;
}

.tire_spare {
  width: 6rem;
  height: 1.5625rem;
  margin-top: 2rem;
}

.tire_empty {
  border-radius: 5px;
  background-image: linear-gradient(to right, gray, gray, gray 5px, #e5e5f7);
  background-size: 10px 100%;
}

.tire_filled_no_sulco {
  border-radius: 5px;
  background-image: linear-gradient(to right, black, black, black 5px, #e5e5f7);
  background-size: 10px 100%;
}

.tire_filled {
  border-radius: 5px;
  background-image: linear-gradient(
    to right,
    rgb(0, 156, 36),
    rgb(0, 156, 36) 5px,
    rgb(0, 156, 36) 5px,
    #e5e5f7
  );
  background-size: 10px 100%;
}

.tire_filled_medium {
  border-radius: 5px;
  background-image: linear-gradient(
    to right,
    rgb(239, 219, 0),
    rgb(239, 219, 0) 5px,
    rgb(239, 219, 0) 5px,
    #e5e5f7
  );
  background-size: 10px 100%;
}

.tire_filled_low {
  border-radius: 5px;
  background-image: linear-gradient(
    to right,
    rgb(209, 0, 0),
    rgb(209, 0, 0) 5px,
    rgb(209, 0, 0) 5px,
    #e5e5f7
  );
  background-size: 10px 100%;
}

.cdPneuEmpty {
  border: 1px solid #000;
  width: 60px;
  height: 24px;
  margin-top: 6px;
}

.cdPneu {
  color: black;
  font-size: 0.75rem;
  display: block;
  font-weight: bold;
  /* border-bottom: 1px solid black; */
}

.nrSulco {
  color: rgb(50, 50, 50);
  font-size: 0.65rem;
  display: block;
  font-weight: bold;
  /* border-bottom: 1px solid black; */
}

.font {
  color: #000000;
  font-weight: bold;
  font-size: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
}
