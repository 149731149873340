.treeLabel {
  margin-bottom: 0px;
  margin-top: 8px;
}

.tree {
  border: solid 1px #ced4da;
  border-radius: 4px;
}

.itemList {
  list-style: none;
  margin-left: -36px;
}

.itemList__div {
  /* background-color: antiquewhite; */
}

.itemButtonLabel {
  display: flex;
  align-items: center;
  text-transform: capitalize;

  /* background-color: antiquewhite; */
}

.treeChildrens {
  border-style: none;
}

.icon-color-black {
  color: black;
}

.icon-color-gray {
  color: rgba(0, 0, 0, 0.05);
}

.markColorBlue {
  color: white;
  background-color: #1c76a0;
  border-radius: 4px;
  padding: 4px 8px;
}
