.csd-popover {
  box-shadow: 0 0.4em 0.8em 0.2em
    rgba(red($primary), green($primary), blue($primary), 0.6);
}

.csd-popover-body {
  margin-right: 2px;
  margin-left: 2px;
}

.d-inline-block {
  cursor: pointer;
}

.csd-popover-header-primary {
  color: #ffffff;
  background-color: $primary;
}

.csd-popover-header-success {
  color: #ffffff;
  background-color: $success;
}

.csd-popover-header-danger {
  color: #ffffff;
  background-color: $danger;
}

.csd-popover-header-warning {
  color: #000000;
  background-color: $warning;
}

.csd-popover-header-info {
  color: #000000;
  background-color: $info;
}

.csd-popover-header-dark {
  color: #ffffff;
  background-color: $dark;
}

.csd-popover-header-light {
  color: #000000;
  background-color: $light;
}

.csd-popover-header-icon {
  margin-right: 10px;
}
