.toolbar.btn-group {
  button {
    border-radius: 0;
  }

  .dropdown,
  > button {
    border-right: none;
    border-left: 1px solid lightgray;
  }

  .dropdown:first-child,
  > button:first-child {
    border-left: none;
  }

  .dropdown-item {
    outline: none;
    &.active,
    &:active {
      color: #1e2125;
      background-color: #f8f9fa;
    }
  }
}
