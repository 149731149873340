.bodyCar {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.body_structure_area_without_tire {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 26rem;
}

.body_structure_area_without_tire_left {
  border: 4px solid gray;
  border-radius: 2px solid gray;
  width: 6rem;
  height: 5rem;
  border-right: 5px solid gray;
  border-top: 0;
  border-bottom: 0;
}

.body_structure_area_without_tire_right {
  border: 4px solid gray;

  width: 6rem;
  height: 5rem;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
}
