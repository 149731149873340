.dropdown.dropdown-multi {
  min-height: 33px;
  cursor: pointer;
  &[disabled] {
    cursor: default;
  }
  .form-select {
    position: absolute;

    .dropdown-display {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      .display-item {
        margin-right: 5px;
        color: $primary;
        font-weight: 600;
        // &::after {
        //   content: ', ';
        // }
      }
    }
  }

  .dropdown-view {
    @extend .dropDownCommonStyle;
    width: 100%;
    position: absolute;
    top: 33px;
    z-index: 9991;
    background-color: #fff;
    max-height: 15rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background: lighten($primary, 35%);
    }
    .dropdown-view-toolbox {
      padding: 0.4rem 0.6rem;
      border-bottom: 0.01em solid #ebebeb;
      display: flex;
      justify-content: space-between;
      .bordered-input-group {
        @extend .form-control;
        display: flex;
        align-items: center;
        padding: 0.2em 1em;
        padding-right: 0;
        .checkbox-container {
          padding-right: 0.2em;
          border-right: 1px solid #dadada;
          .form-check-input {
            margin-left: 0;
            margin-right: 0.35rem;
          }
        }

        input[type='text'] {
          border: 0;
        }
      }
    }
    .dropdown-multi-item {
      border-bottom: 0.01em solid #ebebeb;
      padding: 0.25rem 0.5rem;

      &:hover,
      &:focus {
        @extend .bg-gradient;
        background-color: $primary;
        color: color-contrast($primary);
      }

      .form-check-label {
        cursor: pointer;
        width: 100%;
      }
    }
  }
  &[disabled] {
    .form-select {
      background-color: #e9ecef;
    }
  }
}
