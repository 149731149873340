.autoComplete {
  > button.btn {
    padding: 0.2rem 0.75rem;
  }

  .autocomplete {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;

    .autocomplete-select {
      ul.autocomplete-menu {
        @extend .dropDownCommonStyle;
        position: absolute;
        z-index: 992;
        background-color: #fff;
        width: 100%;
        overflow: auto;
        max-height: 15rem;
        padding: 0;
        margin: 0;
        cursor: pointer;

        &::-webkit-scrollbar {
          background: lighten($primary, 35%);
        }

        li {
          text-align: left;
          border-bottom: 0.01em solid #ebebeb;
          padding: 0.5rem 0.8rem;
          font-size: 0.8rem;
          button,
          button:focus {
            text-align: left;
            background-color: transparent;
            border: none;
            outline: none;
          }
          &:hover {
            @extend .bg-gradient;
            background-color: $primary;
            color: color-contrast($primary);
            button {
              color: color-contrast($primary);
            }
          }
          &.active {
            @extend .bg-gradient;
            background-color: $primary;
            color: color-contrast($primary);
            button {
              color: color-contrast($primary);
            }
          }
        }
      }
    }

    input {
      padding-right: 1.8rem;
    }

    .autocomplete-tools-box {
      position: absolute;
      right: 0.4rem;
      top: 0.35rem;
      padding-right: 0.25rem;
      background-color: transparent;

      .autocomplete-clear {
        cursor: pointer;
        color: $primary;
        &:hover {
          color: darken($danger, 15%);
        }
      }

      .autocomplete-loader-box {
        padding-left: 0.4rem;
        display: none;
        border-left: 1px solid lighten($primary, 30%);
      }
    }
    &.show {
      input {
        padding-right: 45px;
      }
      .autocomplete-loader-box {
        display: block;
      }
    }
  }
}
