.panel.accordion {
  .accordion-button:not(.collapsable) {
    cursor: default;
  }
  button:focus,
  button.focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .accordion-button {
    padding: 0.5rem 1rem 1rem 1rem;
    background-color: #fff;

    &::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }

    &.opened {
      &::after {
        transform: rotate(0);
      }
    }

    &:not(.collapsable) {
      &::after {
        background-image: none;
      }
      &:hover {
        background-color: #fff;
      }
    }

    &.btn-primary {
      @extend .btn-primary;

      &:not(.collapsable) {
        &:hover {
          background-color: $primary;
        }
      }
    }

    &.btn-secondary {
      @extend .btn-secondary;
      &:not(.collapsable) {
        &:hover {
          background-color: $secondary;
        }
      }
    }

    &.btn-success {
      @extend .btn-success;
      &:not(.collapsable) {
        &:hover {
          background-color: $success;
        }
      }
    }

    &.btn-danger {
      @extend .btn-danger;
      &:not(.collapsable) {
        &:hover {
          background-color: $danger;
        }
      }
    }

    &.btn-warning {
      @extend .btn-warning;
      color: #fff;
      &:not(.collapsable) {
        &:hover {
          background-color: $warning;
        }
      }
    }

    &.btn-info {
      @extend .btn-info;
      color: #fff;
      &:not(.collapsable) {
        &:hover {
          background-color: $info;
        }
      }
    }

    &.btn-light {
      @extend .btn-light;
      color: #000;
      &::after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
      }

      &:not(.collapsable) {
        &:hover {
          background-color: $light;
        }
      }
    }

    &.btn-dark {
      @extend .btn-dark;
      &:not(.collapsable) {
        &:hover {
          background-color: $dark;
        }
      }
    }

    &.btn-inactive {
      @extend .btn-inactive;
      color: #fff;
      &:not(.collapsable) {
        &:hover {
          background-color: $inactive;
        }
      }
    }
  }

  .accordion-body {
    padding: 0;
  }
  .panel-body {
    padding: 1em;
  }
  .panel-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0.2em 0.5em;
  }
}
