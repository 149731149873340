.axis {
  display: flex;
  flex-direction: row;
  margin-top: -0.3125rem;
}

.axis_side {
  width: 7rem;
  display: flex;
  gap: 0.2rem;
  flex-direction: row;
  z-index: 0;
}

.axis_side_right {
  justify-content: flex-end;
}

.central_collumn {
  width: 26rem;
  height: 0.5rem;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  z-index: 0;
  top: 3.5rem;
  background-color: #9b9393;
}

.engine {
  position: relative;
}

.engine_block {
  width: 7rem;
  height: 3rem;
  border: 3px solid black;
  background-color: #ced4da;
  border-radius: 1rem;
  position: absolute;
  left: 9.5rem;
  top: 2rem;
  z-index: 2;
}

.without_engine {
  width: 3rem;
  height: 3rem;
  border: 3px solid gray;
  background-color: #fbfbfb;
  border-radius: 3rem;
  position: absolute;
  left: 11.5rem;
  top: 2.3rem;
  z-index: 2;
}

.central_space {
  width: 8rem;
  border: 3px solid gray;
  margin-left: -4rem;
  margin-top: -1rem;

  border-bottom: 0;
  border-top: 0;
}

.body_structure_area_without_tire {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.body_structure_area_without_tire_left {
  border: 4px solid gray;
  border-radius: 8px 0 0 0;
  width: 6rem;
  height: 6.5rem;
  border-right: 5px solid gray;
}

.body_structure_area_without_tire_right {
  border: 4px solid gray;
  border-radius: 0px 8px 0 0;
  width: 6rem;
  height: 6.5rem;
  border-left: 0;
}

.withou_bottom_border {
  border-bottom: 0;
}

.without_top_border {
  border-top: 0;
  border-radius: 0;
}

.without_border_top_bottom {
  border-top: 0;
  border-bottom: 0;
  border-radius: 0;
}
