/* Login */
body.login {
  min-height: 100%;
  border: 0;
  font-family: Lato, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background-color: none;
  background: url(../img/bgLogin.jpg) repeat;
  overflow-y: auto;
}
.login-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  background-color: white;
  border-radius: 0.5em;

  .boxCelebre {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* HOME */

//$menu-color: #3a4651;
$menu-color: $primary;
.main-content-box {
  display: flex;
  min-height: 100vh;
  .main-content-menu {
    width: auto;
  }
  .main-content {
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0.5em;
    }

    .header {
      min-height: 3em;

      background-color: $white;
      padding-left: 1em;
      box-shadow: 0 2px 2px 0 rgba(60, 75, 100, 0.14),
        0 3px 1px -2px rgba(60, 75, 100, 0.12),
        0 1px 5px 0 rgba(60, 75, 100, 0.2);
      &.row {
        margin-right: 0;
        padding-bottom: 5px;
      }

      > .row {
        margin-right: 0;
        > * {
          padding-right: 0;
        }
      }
      .row {
        height: 100%;
        align-items: center;
        > * {
          padding: 0.2rem 0.3rem;
        }
      }

      .btn-menu-collapse {
        background-color: darken($menu-color, 20%);
        color: color-contrast(darken($menu-color, 20%));
      }

      .btn-page {
        padding: 0.2rem 0.5rem;
        position: relative;

        .btn-close {
          width: 12px;
          height: 12px;
          background: none;
          background-color: $danger;
          position: absolute;
          right: -10px;
          top: -8px;
          border-radius: 50%;
          color: white;
          font-size: 0.7em;
          border: 0.1em solid white;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.15s ease-in-out;
          &:hover {
            width: 14px;
            height: 14px;
            right: -10px;
            top: -8px;
            font-size: 1em;
          }
        }
      }

      .right-menu {
        padding: 0 1em 0 2em;
        height: 100%;
        color: $primary;
        font-size: 0.8rem;
        font-weight: 700;
        cursor: pointer;
        img {
          padding: 0;
          width: 42px;
        }
      }
    }

    .main-content-container {
      padding: 1em;
      font-size: 14px;
      max-height: 1px; /* Trick to make horizontal scrollbar always visible */
      iframe,
      embed {
        width: 100%;
        min-width: calc(100vw - 270px);
        min-height: 300vh;
      }

      .iframe-old-pages {
        background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100% 100%"><text fill="%231c76a0" x="50%" y="50%" font-family="sans-serif" font-size="24" text-anchor="middle">Carregando...</text></svg>')
          0px 0px no-repeat;
      }
    }
  }
  .nav-tabs .nav-item .btn-check:focus + .btn,
  .btn:focus {
    box-shadow: none;
  }
  .tab-pane {
    background-color: $white;
    position: relative;
    border-radius: 0 0.25rem 0.25rem 0.25rem;
    display: inline-block;
    min-width: 100%;
    > .loader-spinner {
      top: 50px;
    }
  }
}

.sidebar-box {
  background-color: darken($menu-color, 20%);
  height: 100%;
  width: 228px;
  position: relative;
  transition: width 0.3s ease-in-out;
  .sidebar {
    transition: width 0.3s ease-in-out;
    position: fixed;
    min-height: calc(100vh - 10px);
    max-height: calc(100vh - 10px);
    width: 228px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.5em;
    }

    .brand {
      background-color: darken($menu-color, 25%);
      color: color-contrast($menu-color);
      display: flex;
      align-items: center;
      height: 3em;
      padding: 0.5em;
      img {
        margin: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    input {
      margin: 5%;
      width: 90%;
      padding: 0 10px;
      height: 30px;
      font-size: 12px;
      border: 1.5px solid darken($menu-color, 25%);
      border-radius: 20px;
      background-color: darken($menu-color, 25%);
      color: #fff;
      outline-width: 0;
      margin-top: 10px;
    }
    ul {
      list-style: none;
      color: hsla(0, 0%, 100%, 0.5);
      font-size: 12px;
      padding-left: 0;

      li .menu-item {
        cursor: pointer;
        padding: 10px 10px 10px 15px;

        &:hover {
          color: #fff;
          background-color: $menu-color;
        }
      }

      svg {
        font-size: 14px;
        width: 22px;
      }
      .menu-arrow {
        float: right;
        font-size: 20px;
        line-height: normal;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        margin-right: 0;
      }
    }

    & > ul > li {
      > .menu-item {
        padding-left: 1.5em;
      }
      & > ul > li {
        .menu-item {
          padding-left: 3em;
          background-color: darken($menu-color, 18%);
        }
        & > ul > li {
          .menu-item {
            padding-left: 4.5em;
            background-color: darken($menu-color, 16%);
          }
        }
      }
    }

    ul > li > .menu-item[aria-expanded='true'] > .menu-arrow {
      transform: rotate(90deg);
    }
  }

  &.minified {
    width: 0;
    .sidebar {
      width: 0;
    }
  }

  .highlight {
    background: rgba($primary, 0.6);
    color: #e2e2e2;
  }
}

/* Example Page */
.example-container {
  min-height: 100vh;

  main {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;

    .example-menu-container {
      width: 230px;
      background-color: darken($CSDPurple, 32%);

      .example-menu-dock {
        width: 240px;
        position: fixed;

        .example-menu {
          padding: 1rem 0 1rem 0.6rem;
          height: 100vh;
          .example-menu-header {
            margin-left: 10px;
            font-weight: bold;
            font-size: 0.5rem;
            svg {
              font-size: 1.5rem;
            }
          }

          button.nav-link {
            color: #b3b3b3;
            text-align: start;
            &:hover {
              color: #fff;
            }
            &.active {
              background-color: $CSDPurple;
              color: #fff;
            }
          }

          .logo-celebre {
            margin-left: 7%;
            width: 80%;
          }
        }
      }
    }

    .container {
      flex: 1 0 0%;
    }
  }

  .form-check {
    display: inline-block;
    margin: 0 0.5rem;
  }
  .form-label {
    display: block;
  }
}
