.date-picker {
  float: left;
  position: relative;
  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
  }

  .cc-month,
  .cc-head,
  .cch-name,
  .cc-body,
  .button-day span,
  .button-day,
  .day-container,
  .c-container,
  .today-button {
    position: relative;
    display: block;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .date-picker-input {
    @extend .form-control;

    &::-webkit-clear-button,
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
  .date-picker-calendar {
    float: left;
    position: absolute;
    left: 0;
    width: 230px;
    min-height: 250px;
    background: #fff;
    border-radius: 0.2rem;
    overflow: hidden;
    z-index: 999;
    margin-top: 0.2rem;
    box-shadow: 5px 10px 18px rgba(0, 0, 0, 0.8);

    .date-picker-calendar-header {
      width: 100%;
      padding: 0.5rem;
      @extend .bg-gradient;
      background-color: $primary;
      color: color-contrast($primary);
      font-size: 0.7rem;
      .date-picker-controls {
        display: flex;
        .header-control {
          width: auto;
          margin: 0 0.3em;
          width: 34px;
          padding: 0.2em;
          text-align: center;
          border-radius: 20%;
          &:hover {
            background-color: darken($primary, 10%);
            cursor: pointer;
          }
        }
        .header-text {
          text-align: center;
          width: 100%;
          font-size: 1.2em;
          font-weight: 700;
        }
      }
      .week-days {
        display: flex;
        .week-day-container {
          text-align: center;
          width: calc(100% / 7);
        }
      }
    }

    .date-picker-calendar-body {
      .button-day {
        width: 100%;
        height: 100%;
        font-size: 12px;
        font-weight: 300;
        color: #444;
        text-align: center;

        span {
          width: 100%;
          height: 100%;
          font-size: 12px;
          font-weight: 300;
          color: #444;

          width: 30px;
          height: 30px;
          margin-top: -15px;
          margin-left: -15px;
          left: 50%;
          top: 50%;
          font-weight: 400;
          border-radius: 20%;
          line-height: 30px;

          &:hover {
            cursor: pointer;
            background-color: transparentize($CSDPurple, 0.7) !important;
          }
        }
      }

      .day-container.disabled {
        pointer-events: none;
        .button-day span {
          background-color: #fff !important;
          color: #ddd;
        }
      }
      .day-container.current-date .button-day span {
        background-color: transparentize($primary, 0.7);
      }
      .day-container.selected-date .button-day span {
        background-color: transparentize($CSDPurple, 0.7);
      }
    }

    .today-button {
      @extend .bg-gradient;
      background-color: $primary;
      color: color-contrast($primary);
      cursor: pointer;
      padding: 0.4em;
      width: 100%;
      text-align: center;
      &:hover {
        background-color: darken($primary, 5%);
      }
    }
  }
}

.mdpc-body {
  float: left;
  width: 100%;
  margin-top: 20px;
}

/**
 * Controls
 */

.mdpchb-inner:hover > span {
  border-color: #555 !important;
}
.mdpchb-inner:hover {
  cursor: pointer;
  background: #eee;
}
.mdpchb-inner {
  float: left;
  height: 35px;
  width: 35px;
  background: #f4f4f4;
  border-radius: 100%;
  line-height: 35px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -17px;
  margin-top: -17px;
}

.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after,
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows {
  display: block;
  float: left;
  width: 6px;
  height: 6px;
  border-left: 2px solid #888;
  border-bottom: 2px solid #888;
  position: absolute;
}
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows {
  transform: rotate(45deg);
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -4px;
}
.mdpchbi-right-arrows,
.mdpchbi-right-arrow {
  transform: rotate(225deg);
  margin-left: -4px;
}
.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after {
  content: "";
}

.mdpchbi-left-arrows {
  margin-left: -5px;
}
.mdpchbi-right-arrows {
  margin-left: -2px;
}
.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after {
  left: 3px;
  top: -5px;
}

.mdpch-container {
  float: left;
  width: 120px;
  height: 100%;
}
.mdpchc-year {
  float: left;
  width: 100%;
  height: 30px;
  font-size: 27px;
  font-weight: 200px;
  text-align: center;
}
.mdpchc-month {
  float: left;
  width: 100%;
  height: 15px;
  font-size: 13px;
  font-weight: 200px;
  text-align: center;
}

/**
 *  Calendar
 */

.c-container {
  width: 100%;
  height: 100%;
}

.cc-month {
  height: 30px;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  line-height: 30px;
  color: #666;
}
.cc-head {
  height: 30px;
  width: 100%;
  margin-top: 10px;
}
.cch-name {
  width: 14.285%;
  height: 30px;
  line-height: 30px;
  font-weight: 700;
  color: #666;
  font-size: 9px;
  text-align: center;
}
.cc-body {
  height: 190px;
  width: 100%;
  padding: 0.5rem;
}
.day-container {
  width: 14.285%;
  height: 16.6666%;
}
