.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-content {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 14px;
  opacity: 0.9;
  display: none;
}

.custom-tooltip:hover .tooltip-content {
  display: block;
}
